export const LaminadosData ={
    stylesMate:{
        1:
        [
            'aluminio-arosa-soft',
            'verde-oliva-soft',
            'very-berry-soft',
            'negro-soft',
            'verde-arcilla-soft',
            'rojo-pompeya-soft',
            'gris-gu-soft',
            'verde-salvia-soft',
            'rojo-pompeya-soft',
            'gris-gu-soft',
            'verde-salvia-soft',
            'rosa-talco-soft',
        ],
        2:
        [
            'azul-indigo-touch',
            'amarillo-pompeya-soft',
            'rojo-touch',
            'verde-talco-soft',
            'malva-talco-soft',
        ],
        3.1:
        [
            'blanco-touch',
            'blanco-soft',
            'crema-sil-soft',
            'nube-touch',
        ],
        3.2:
        [
            'negro-touch',
            'blanco-soft',
            'crema-sil-soft',
            'crema-touch',
        ],
        4:
        [
            'artico',
            'smeraldo',
            'lignite',
            'terracotta',
            'blue',
            'legno-fume',
            'bianco-zen',
            'visone',
            'nero',
            'nuvola',
            'antracite',
            'arena',
            'toscano',
            'elba',
        ],
        5:
        [  
            'cemento',
            'carrara',
            'crema',
            'bronce',
            'blanco',
            'negro',
            'arena',
            'agua-marina',
            'azul',
            'gris-claro',
            'marengo',
            'rojo',
        ],
        6:
        [
            'negro-ingro',
            'grigio-efeso',
            'castoro-ottawa',
            'grigio-bromo',
            'bianco-kos',
            'grigio-londra',
        ]
    
    },
    
    namesMate:{
        1:[
            'ALUMINIO AROSA SOFT',
            'VERDE OLIVA SOFT',
            'VERY BERRY SOFT',
            'NEGRO SOFT',
            'VERDE ARCILLA SOFT',
            'ROJO POMPEYA SOFT',
            'GRIS GU SOFT',
            'VERDE SALVIA SOFT',
            'ROSA TALCO SOFT',
        ],
        2:
        [
            'AZUL ÍNDIGO TOUCH',
            'AMARILLO POMPEYA SOFT',
            'ROJO TOUCH',
            'VERDE TALCO SOFT',
            'MALVA TALCO SOFT',
        ],
        3.1:
        [
            'BLANCO TOUCH',
            'BLANCO SOFT',
            'CREMA SIL SOFT',
            'NUBE TOUCH',
        ],
        3.2:
        [
            'NEGRO TOUCH',
            'BLANCO SOFT',
            'CREMA SIL SOFT',
            'CREMA TOUCH',
        ],
        4:
        [  
            'ÁRTICO',
            'SMERALDO',
            'LIGNITE',
            'TERRACOTA',
            'BLUE',
            'LEGNO FUMÉ',
            'BIANCO ZEN',
            'VISONE',
            'NERO',
            'NUVOLA',
            'ANTRACITE',
            'ARENA',
            'TOSCANO',
            'ELBA',
        ],
        5:
        [  
            'CEMENTO',
            'CARRARA',
            'CREMA',
            'BRONCE',
            'BLANCO',
            'NEGRO',
            'ARENA',
            'AGUA MARINA',
            'AZUL',
            'GRIS CLARO',
            'MARENGO',
            'ROJO',
        ],
        6:
        [
            'NEGRO INGRO',
            'GRIGIO EFESO',
            'CASTORO OTTAWA',
            'GRIGIO BROMO',
            'BLANCO KOS',
            'GRIGIO LONDRA',
        ]
    },
    
    stylesBrillo:{
        1:
        [
            'blanco-brillo',
            'blanco-seda',
            'antique-white-brillo',
            'levante-brillo',
            'carnaval-brillo',
            'ciclone-brillo',
        ],
        2:
        [
            'storm-brillo',
            'diamond-black-brillo',
            'just-blue-brillo',
            'maui-brillo',
            'dening-brillo',
            'just-gold-brillo',
        ],
        3:
        [
            'lemon-brillo',
            'kashmir-brillo',
            'new-burgundy',
            'krypton-metalizado-brillo',
            'glacial-brillo',
            'tierra-brillo-gloss',
            'br-blanco',
            'br-beige',
            'br-nube',
            'br-grafito',
            'br-terra'
        ]
    },

    namesBrillo:{
        1:
        [
            'BLANCO BRILLO',
            'BLANCO SEDA',
            'ANTIQUE WHITE BRILLO',
            'LEVANTE BRILLO',
            'CARNAVAL BRILLO',
            'CICLONE BRILLO',
        ],
        2:
        [
            'STORM BRILLO',
            'DIAMOND BLACK BRILLO',
            'JUST BLUE BRILLO',
            'MAUI BRILLO',
            'DENING BRILLO',
            'JUST GOLD BRILLO',
        ],
        3:
        [
            'LEMON BRILLO',
            'KASHMIR BRILLO',
            'NEW BURGUNDY',
            'KRYPTON METALIZADO BRILLO',
            'GLACIAL BRILLO',
            'TIERRA BRILLO GLOSS',
            'BR BLANCO',
            'BR BEIGE',
            'BR NUBE',
            'BR GRAFITO',
            'BR TERRA',
        ]
    },

    stylesWood:{
        1:
        [
            'roble-hercules-atlas',
            'roble-osstipo-atlas',
            'roble-aurora-atlas',
            'fresno-estepa-sega',
            'heaven-oak-atlas',
            'iron-oak-atlas',
            'oak-market-atlas',
            'roble-pegasus-atlas',
            'roble-denver-atlas',
            'roble-sinatra-essence',
            'prime-wood-legno',
            'oak-river-atlas',
            'roble-arezzo-soul',
            'roble-nordico-atlas',
        ],
        2:
        [
            'W4452',
            'W4479',
            'W4479-1R',
            'W4479-R',
            'W4487',
            'W4569',
            'W4570',
            'W4574',
            'W4603',
            'W4619',
            'W4622',
            'W4654',
            'W4660',
            'W4673',
            'W4677',
            'W4678',
            'W4692',
            'W4694',
            'W4698',
            'W4697',
            'W4701',


        ],
    },

    namesWood:{
        1:
        [
            'Roble Hércules Atlas',
            'Roble Osstipo Atlas',
            'Roble Aurora Atlas',
            'Fresno Estepa Sega',
            'Heaven Oak Atlas',
            'Iron Oak Atlas',
            'Oak Market Atlas',
            'Roble Pegasus Atlas',
            'Roble Denver Atlas',
            'Roble Sinatra Essence',
            'Prime Wood Legno',
            'Oak River Atlas',
            'Roble Arezzo Soul',
            'Roble Nórdico Atlas',
        ],
        2:
        [
            '4452 Poro doble',
            '4479 Seda Mate - Poro doble',
            '4479 1R - Poro doble',
            '4479 R - Poro doble',
            '4487 Poro doble',
            '4569 Roble - Poro doble',
            '4570 Roble - Poro doble',
            '4574 Poro doble',
            '4603 Poro doble',
            '4619 Poro doble',
            '4622 Poro doble',
            '4654 Poro doble',
            '4660 Poro doble',
            '4673 Poro doble',
            '4677 Poro doble',
            '4678 Poro doble',
            '4692 Roble - Poro pizarra',
            '4694 Roble - Poro pizarra',
            '4698 Roble - Poro pizarra',
            '4697 Roble - Poro pizarra',
            '4701 Roble - Poro pizarra',
        ],
    },


};

export const CanteadoData=
{

    zonas:
        [
            "1", "1", "1", "1",
            "1", "1", "1", "1",
            "1", "1", "1", "1", "1", "1",
            "1", "1", "1", "1", "1", 
            "2", "2", "2", "2", "2", "2",
            "2", "2", "2", "2", "2", "2",
            "2", "2", "2", "2", "2", "2", "2",
        ],

    modelos:
        [
            "Trama", "Natumel Tirador Berna", "Royal Wood Tirador Pocket", "Legno",
            "Natur + lino Tirador Pocket", "Natur + lino", "Natur + Super Mate", "Natur + Super Mate",
            "State Tirador Pocket", "State Tirador Forma", "State Tirador Pocket", "State Tirador Berna", "State Tirador Berna", "State Tirador Forma",
            "State Tirador Berna", "State Tirador Pocket", "State Tirador Forma", "State Vega", "State Vega", 
            "Touch Tirador Forma", "Touch Tirador Berna", "Touch Tirador Berna", "Touch Tirador Pocket", "Touch Vega", "Touch",
            "State Calabria", "State Calabria", "State Calabria", "State Calabria", "State Calabria", "State Calabria",
            "Touch Tirador Forma", "Touch Tirador Berna", "Touch Tirador Forma", "Touch Vega", "GM", "Maxna", "Maxna",
            
        ],

    acabados:
        [
            "Roble Marrón", "Roble Glacial", "Blanco", "Argilla",
            "Lino Medio", "Lino Claro", "Arena", "Olive", 
            "N 4590", "N 4591", "N 4603", "N 4660", "N 4674", "N 4677",
            "Grafito", "Mostaza", "Angora", "N 4673", "N 4371",
            "Azul Indigo", "Capuccino Seda", "Grafito Seda", "Nube Seda", "Negro Seda", "Terra Brillo",
            "N 4569", "N 4671", "N 4675", "N 4678", "N 4701", "Blanco",
            "Capuccino Brillo", "Grafito Brillo", "Nube Brillo", "Beige Brillo", "Ciclone", "Olive", "Ebano Noblesse"
        ],

    grosores :
        [
            "22 mm", "19 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", "21 mm", "18 mm", "18 mm",
        ],

    urls:
        [
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968695/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/12_TRAMA_fzfm1v.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968695/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/13_NATURMEL_TIRADOR_BERNA_n6pady.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968695/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/14_ROYAL_WOOD_TIRADOR_POCKET_m1wk5c.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968696/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/15_LEGNO_tawnba.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968694/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/08_NATUR_LINOTIRADOR_POCKET_pebpv7.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968694/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/09_NATUR_LINO_db4udi.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968694/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/11_NATUR_SUPER_MATE_ucdc2l.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968695/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/10_NATUR_SUPER_MATE_frelar.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968696/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/21_SLATE_TIRADOR_POCKET_alasst.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968696/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/22_SLATE_TIRADOR_FORMA_ahnlws.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968696/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/23_SLATE_TIRADOR_POCKET_d4ipxd.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968697/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/24_SLATE_TIRADOR_BERNA_m8ax9w.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968696/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/25_SLATE_TIRADOR_BERNA_qtji5f.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968697/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/26_SLATE_TIRADOR_FORMA_y1hw4e.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968696/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/25_SLATE_TIRADOR_BERNA_qtji5f.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968695/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/17_SLATE_TIRADOR_POCKET_rouoho.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968695/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/18_SLATE_TIRADOR_FORMA_ifqqoc.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968695/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/20_SLATE_VEGA_btmlqd.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968696/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/19_SLATE_VEGA_tmsc8x.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968697/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/27_TOUCH_TIRADOR_FORMA_of9ntn.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968697/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/28_TOUCH_TIRADOR_BERNA_lqm2x5.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968697/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/29_TOUCH_TIRADOR_BERNA_spighv.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968693/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/32_TOUCH_TIRADOR_POCKET_h4x8bn.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968692/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/31_TOUCH_VEGA_zhzxao.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968697/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/30_TOUCH_uuverp.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968693/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/33_SLATE_CALABRIA_ui2dzb.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968693/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/34_SLATE_CALABRIA_ab5w6c.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968693/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/35_SLATE_CALABRIA_pdgubr.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968693/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/38_SLATE_CALABRIA_nqstde.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968694/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/37_SLATE_CALABRIA_nao7cg.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968693/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/36_SLATE_CALABRIA_xpxq9b.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968693/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/01_TOUCH_TIRADOR_FORMA_hzkjeg.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968693/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/02_TOUCH_TIRADOR_BERNA_izgas2.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968693/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/03_TOUCH_TIRADOR_FORMA_os7l1g.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968694/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/04_TOUCH_VEGA_l0tgtz.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968694/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/07_GM_ygaopu.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968694/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/06_MAXNA_ACABADO_OLIVE_eebuz7.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621968694/DISE%C3%91OS%20Y%20MATERIALES/3.%20CANTEADO/MATERIALES/05_MAXNA_coxkjb.png",
        ]

};

export const PolilaminadoData=
{

    zonas:
        [
            "2 ó 3 según folio", "2 ó 3 según folio", "2 ó 3 según folio", "2 ó 3 según folio", "2 ó 3 según folio", "4 ó 5 según folio",
            "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio",
            "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio",
            "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio",
            "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio",
            "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio",
            "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", 
            "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", 
            "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", 
            "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio",
            "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio", "4 ó 5 según folio",  
        ],

    modelos:
        [
            "Berna", "Madrid tirador lineal", "Paladio", "Penagos", "Pizarro", "Alfa",
            "Alzira", "Amberers", "Amsterdam", "Ancinale", "Andujar", "Baku Tir Nogal Tinaldi F76",
            "Córcega", "Córcega Curva", "Corfu", "Cronos", "Dali", "Danubio",
            "Dessigual", "Doha", "Domo", "Dubai Tir Azul Nova F73", "Dublin", "Época",
            "Estoril", "Euro", "Florencia", "Genil", "Gémova", "Grecia",
            "Greco  con tacos", "Ibiza", "Jaén", "Kansas plafón rayado", "Leiria 14", "Loira",
            "M.Ángel", "Maella T1", "Maella T2", "Málaga", "Menorca",
            "Mónaco", "Montreal Lisa", "Nilo", "Nube", "Oslo", 
            "Sintra", "Sintra Recta", "Tamesis", "Tapies", "Telde", 
            "Torrox", "Trento", "Varsovia", "Vera", "Versalles",
            "Viena Tirador Inox", "Villanova", "Volga", "Xativa", 
        ],

    folios: 
        [
            "72 Truffle","72 Truffle","72 Stone","28 Roble Oscuro","14 Peral","59 Blanco fresno Seda",
            "58 Oliva Fresno Seda","41 Blanco Diseño Veta horizontal","18 Alder Seda","28 Roble Oscuro","44 Pell di Pesca","68 Blanco Roto Multipunto",
            "44 Pell di Pesca","28 Roble Oscuro","73 Azul Nova","75 Teka Antique","28 Roble Oscuro","5 Blanco Fresno",
            "77 Travertine","76 Nogal Rinaldi","70 Gris Claro","70 Gris Claro","7 Maple Verde","9 Abedul",
            "70 Gris Claro","23 Nogal Nuevo difum avellana","51 Blanco Seca","9 Abedul","8 Maple Azul difum blanco","76 Nogal Rinaldi",
            "6 Crema",  " 40 Berenjena Diseño Veta horizontal", "44 Pelle di Pesca", "S6 Nigú Seda", "15 Cerezo Oscuro difum nogal", "1 Cerezo difum nogal",
            "7 Maple Verde","71 Stone","60 Crema Fresno Seda","S5 Moka Seda difum avellana","18 Alder Seda",
            "33 Blanco Brillo","36 Crema Brillo","15 Cerezo oscuro difum nogal","33 Pet Blanco Brillo","23 Nogal Nuevo difum avellana",
            "2 Haya", "51 Blanco Seda", "4 Blanco Soft", "S6 Nigú Seda", "15 Cerezo oscuro",
            "57 Roble rústico difum nogal", "S5 Moka Seda", "15 Cerezo oscuro", "70 Gris claro", "S6 Nigú Seda",
            "59 Blanco fresno Seda", "69 Arena Claro Multipunto", "68 Blanco Roto Multipunto", "53 Crema Seda",
        ],

    grosores:
        [
            "19/22 mm", "19/22 mm", "19/22 mm", "19 mm", "19 mm", "22 mm",
            "22 mm", "25 mm", "25 mm", "19 mm", "19 mm", "22mm",
            "19 mm", "19 mm", "19 mm", "22 mm", "19 mm", "19 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", "19 mm", "19 mm",
            "19 mm", "19 mm", "22 mm", "19 mm", "19 mm", "22 mm",
            "19 mm", "19 mm", "19 mm", "22 mm", "19 mm", "19 mm",
            "19 mm", "19 mm", "19 mm", "22 mm", "19 mm", 
            "19 mm", "22 mm", "19 mm", "22 mm", "19 mm", 
            "19 mm", "19 mm", "19 mm", "19 mm", "19 mm",
            "19 mm", "22 mm", "19 mm", "22 mm", "19 mm",
            "22 mm", "22 mm", "19 mm", "22 mm", 
        ],

    urls:
        [
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216820/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/01_BERNA_bvgsgw.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216821/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/02_MADRID_TIRADOR_LINEAL_iikwuv.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216820/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/03_PALADIO_rxpypb.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216820/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/04_PENAGOS_pg1fy3.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216820/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/05_PIZARRO_mcz3qj.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216821/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/06_ALFA_wramul.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216821/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/07_ALZIRA_wj88ag.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216821/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/08_AMBERES_krf5qt.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216821/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/09_AMSTERDAM_qfaxqt.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216821/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/10_ANCINALE_u16vlq.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216821/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/11_ANDUJAR_q2q2fk.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216821/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/12_NOGAL_RINALDI_rvwaty.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216822/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/13_C%C3%93RCEGA_kobdtp.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216823/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/14_C%C3%93RCEGA_CURVA_oiflrj.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216822/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/15_CORFU_bs5zqq.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216822/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/16_CRONOS_fzonxd.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216822/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/17_DAL%C3%8D_dlwasy.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216822/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/18_DANUBIO_owyz7i.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216822/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/19_DESSIGUAL_hcy0ng.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216822/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/20_DOHA_erjsdw.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216822/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/21_DOMO_e4ixnn.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216820/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/24_DUBAI_TIR_koz5qd.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216823/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/23_DUBLIN_ajjsvu.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1622216822/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/22_%C3%89POCA_edcfzl.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597665/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/25_ESTONIL_hoxikk.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597665/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/26_EURO_rzsxtl.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597665/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/27_FLORENCIA_gmro4c.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/30_GENIL_a4nw4c.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/29_G%C3%89NOVA_ufc2yy.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597665/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/28_GRECIA_vsx0wx.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/31_GRECO_CON_TACOS_etgdmo.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/33_IBIZA_tqb9um.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/34_JA%C3%89N_ejkmqa.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597667/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/37_KANSAS_PLAF%C3%93N_RAYADO_okhgn3.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/36_LEIRIA_14_oiklpb.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/35_LOIRA_naewl4.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/38_M._%C3%81NGEL_s3joxz.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/39_MAELLA_T1_ldncha.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/40_MAELLA_T2_acsjzf.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597666/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/41_M%C3%81LAGA_eegeqy.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597667/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/42_MENORCA_laq0g6.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597667/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/43_M%C3%93NACO_oxt19q.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597667/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/44_MONTREAL_fxmgqt.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597667/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/45_NILO_ttsihq.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597667/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/46_NUBE_y7j3iw.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597667/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/47_OSLO_ny4rdm.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597663/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/53_SINTRA_g4cala.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597663/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/54_SINTRA_RECTA_kzbd03.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597663/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/55_TAMESIS_jltlrq.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597664/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/56_TAPIES_naz8ze.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597664/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/57_TELDE_xkaiyj.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597668/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/48_TORROX_dogir3.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597662/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/49_TRENTO_jqh82w.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597662/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/50_VARSOVIA_vim7fs.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597662/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/51_VERA_y8yclw.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597663/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/52_VERSALLES_rsdy0m.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597664/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/58_VIENA_TIRADOR_INOX_aaydwk.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597664/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/59_VILLANOVA_jl3vp9.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597664/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/60_VOLGA_svzvi8.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621597662/DISE%C3%91OS%20Y%20MATERIALES/4.%20POLILAMINADOS/MATERIALES/61_XATIVA_f4uea2.png",
        ]

};

export const LacadosData=
{
    
    zonas:
        [
            "6 ó 7 según color lacado", "6 ó 7 según color lacado", "6 ó 7 según color lacado", "6 ó 7 según color lacado",
            "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado",
            "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado",
            "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado",
            "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado",
            "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado",
            "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado",
            "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado",
            "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", 
            "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", 
            "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", "8 ó 9 según color lacado", 
        ],

    modelos:
        [
            "Berna", "Madrid", "Madrid Tirador Forma", "Madrid Tirador Linear",
            "Amberes", "Aneto", "Apolo Filo Cromo", "Aura Perfil Cromado", "Baku Tirador nogal rinaldi", "Bombay",
            "Bruselas", "Caucaso", "Corfu", "Corintia", "Cronos", "Doha",
            "Dubai Tirador Azul nova", "Elena Tirador Nogal", "Época", "Estoril", "Everest", "Everest Tirador Nogal",
            "Florencia", "Florida", "Galdar", "Gante Tirador Nogal", "Grecia", "Kansas Plafón Rayado",
            "Leiria", "Lyon", "Maella T1", "Maella T2", "Málaga", "Mallorca",
            "Menorca", "Menorca", "Miguel Angel", "Mónaco", "Nube", "Oxford",
            "Palencia", "Palma", "París", "Picassent", "Rodano", "Rubens",
            "Salzburgo", "Segura", "Silos", "Soller", "Trento",
            "Vega", "Venus Perfil Cromado", "Versalles", "Villanova", "Viseu", 
            "Volga", "Xativa", "Zamora", "Zeus Perfil Cromado",

        ],

    lacados: 
        [
            "Berenjena", "Blanco Brillo", "Terra Brillo", "Terrosa Brillo",
            "Beige Brocha", "Vulcano Seda", "Blanco Brillo", "Petroleo Metalizado Brillo", "Beige Seda", "Blanco Brillo", 
            "Beige Seda", "Blanco Brillo filos oro", "Azul Marino Seda", "Slice Seda", "Nube Brillo", "Beige Grisaceo Seda",
            "Gris Seda", "Blanco Brillo", "Pergamón Multipunto", "Pergamón Multipunto", "Slice Seda", "Blanco Seda",
            "Pergamón Patinado", "Beige Seda", "Nigú Seda", "Blanco Brillo", "Nigú Seda", "Perla Seda",
            "Nube Seda", "Rústico Seda", "Beige Seda", "Blanco Roto Seda", "Hueso Seda arista viva", "Verde Bosque Brillo",
            "Hueso Seda", "Verde PA 802-Seda", "Beige Seda", "Pergamón Seda", "Gris Brillo", "Pergamón Patinado",
            "Blanco Brillo", "Blanco Roto Brillo", "Hueso Patinado", "Grafito Brillo", "Blanco Seda", "Pergamón Seda",
            "Arena Seda", "Nigú Seda", "Blanco Roto Seda", "Pergamón Seda", "Beige Seda", 
            "Blanco Brillo", "Chocolate Seda", "Beige Brocha", "Blanco Seda", "Blanco Brillo",
            "Beige Seda", "Beige Grisaceo Seda", "Blanco Brillo", "Blanco Brillo", 
        ],

    grosores:
        [
            "19/22 mm", "19 mm", "19 mm", "19 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", "22 mm", "19 mm",
            "22 mm", "22 mm", "19 mm", "22 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "19 mm", "19 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "19 mm", "22 mm", "22 mm", "22 mm",
            "19 mm", "19 mm", "19 mm", "19 mm", "22 mm", "22 mm",
            "19 mm", "19 mm", "19 mm", "19 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", "19 mm", "19 mm",
            "19 mm", "19 mm", "19 mm", "19 mm", "22 mm",
            "19 mm", "19 mm", "19 mm", "19 mm", "22 mm",
            "19 mm", "22 mm", "22 mm", "22 mm",
        ],

    urls:
        [
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960748/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/05_BERNA_w7dkih.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960748/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/06_BLANCO_BRILLO_snokfa.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960748/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/07_MADRID_TIRADOR_FORMA_xuev6j.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960748/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/08_MADRID_TIRADOR_LINEAR_suvqnx.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960749/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/15_AMBERES_bodpbp.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960749/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/16_VULCANO_SEDA_abzcbr.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960748/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/06_BLANCO_BRILLO_snokfa.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960750/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/18_AURA_PERFIL_CROMADO_exjv1d.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960744/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/29_GANTE_TIRADOR_NOGAL_v4qaz2.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960750/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/20_BOMBAY_wfnixq.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960749/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/09_BRUSELAS_mblp0p.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960749/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/10_CAUCASO_jbqkk3.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960750/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/11_CORFU_uu85ms.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960749/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/12_CORINTIA_nuc6hl.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960749/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/13_CRONOS_l5zjom.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960750/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/14_DOHA_hhezoe.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960750/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/21_DUBAI_TIRADOR_AZUL_pxnn5f.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960750/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/22_ELENA_TIRADOR_NOGAL_khcdgi.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960750/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/23_%C3%89POCA_bxhktx.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960750/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/25_ESTORIL_uflp3w.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621969351/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/60_EVEREST_nxsra3.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960750/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/24_EVEREST_TIRADOR_NOGAL_ptha6j.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960751/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/26_FLORENCIA_ejhhjx.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960744/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/27_FLORIDA_r5pdsk.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960744/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/28_GALDAR_dlz2wb.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960744/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/29_GANTE_TIRADOR_NOGAL_v4qaz2.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960744/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/30_GRECIA_zyijid.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960744/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/31_KANSAS_PLAFON_RAYADO_bwcuru.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960745/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/32_LEIRIA_jvayma.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960745/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/33_LYON_j3wgjk.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960745/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/34_MAELLA_T1_vwop8r.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960745/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/37_MAELLA_T2_u3zby6.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960745/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/36_M%C3%81LAGA_msyfqo.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960745/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/35_MALLORCA_kuf77j.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960746/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/38_MANACOR_eqyjzn.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960746/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/39_MENORCA_lkwwj4.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960746/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/40_MIGUEL_ANGEL_tl2vp9.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960747/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/43_M%C3%93NACO_ul9ncd.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960746/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/42_NUBE_vdwmui.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960746/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/41_OXFORD_bf0as9.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960747/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/44_PALENCIA_dlecvs.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960747/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/45_PALMA_nlxtxu.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960747/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/46_PARIS_tqevq0.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960748/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/49_PICASSENT_cyxyhz.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960747/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/48_RODANO_ipzpgl.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960747/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/47_RUBENS_nyas6x.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960748/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/50_SALZBURGO_gciahg.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960748/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/51_SEGURA_ojj5xf.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960748/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/52_SILOS_yw2gnz.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960745/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/54_SOLLER_nmpkd9.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960744/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/53_TRENTO_jswhec.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960744/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/55_VEGA_qhctrb.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960748/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/56_VENUS_PERFIL_CROMADO_cfrqi3.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960745/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/57_VERSALLES_tjchjg.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960746/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/59_VILLANOVA_ivsmxt.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960746/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/58_VISEU_gbezjk.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960746/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/01_VOLGA_nrs9qe.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960746/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/02_XATIVA_tspnwp.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960747/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/03_ZAMORA_uppzrp.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621960747/DISE%C3%91OS%20Y%20MATERIALES/5.%20LACADOS/MATERIALES/04_ZEUS_PERFIL_CROMADO_c7ugma.png",
        ]
    
};

export const MaderaData=
{
    zonas:
        [
            "10", "10", "10", "10", "10", "10",
            "10", "10", "10", "10", "10", "10",
            "10", "10", "10", "10", 
            "10", "10", "10", "10", 
            "10", "10", "10",
            "10", "10", "10", "10", 
            "11", "11", "11", "11", "11" ,
            "11", "11", "11", "11", "11" ,
            "12", "12", "12", "12",
            "11", "11", "11", "11", "11",
            "12", "12", "12", "12",
            "12", "12", "12", "12", "12",
        ],

    modelos:
        [
            "Alaska 10 Alder", "Asturias Roble", "Bahía Roble Nudos", "Calabria 10 Alder", "Calabria 10 marino Cerezo", "Cantabria 8 Castaño", 
            "Coimbra Roble", "Espinosa Cerezo", "Lerira 14 Cerezo", "Oviedo Castaño", "Penagos Nogal", "Segura Alder",
            "Palencia Albeto Veta Vert Tricapa", "Palencia Albeto Veta Vert Tricapa", "Palencia Albeto Veta Vert Tricapa", "Palencia Albeto Veta Horiz Tricapa", 
            "Palencia Albeto Veta Vert Tricapa", "Madrid Roble PF", "Madrid Roble PF", "Madrid Roble Natural Veta Horiz",  
            "Vega Roble nudos", "Vega Roble nudos", "Vega Roble nudos",
            "Vega Roble PF", "Vega Roble PF", "Vega Roble PF", "Vega Abeto Veta Vert Tricapa", 
            "Andros Fresno", "Corinitia Roble plafón rayado", "Copenhague Fresno", "Egabro Roble", "Kansas Castaño Plafón rayado", 
            "Milán Fresno", "Nastur Roble", "Niza Roble", "Nube Nogal", "Palma Nogal",
            "Amberes Alder", "Bari Roble macizo", "Cies Roble", "Córcega Roble",
            "Paris Roble", "Picassent Nogal", "Salzburgo Nogal", "Salzburgo Roble nudos", "Snatorini Pino",
            "Évora Roble", "Ítaca Castaño", "Lisboa Alder", "Oporto Roble",
            "Roma Roble", "Seul Roble", "Toledo Roble plafón macizo", "Venecia Roble", "Versalles Alder",
        ],

    acabados: 
        [
            "110", "101 L", "H05", "190", "190", "102",
            "Piedra Patinado", "128", "128", "218", "Nogal Nº5" , "LBR",
            "Beige", "Gris Oscuro", "Miel Poro Arenado", "Blanco Poro Arenado", 
            "Verde", "Gris Oscuro", "Blanco", "Natural",
            "H01", "H02", "H04",
            "Beige", "Gris Claro", "Verde", "Gris Claro", 
            "D06", "L09", "Poro Piedra", "Roble Viejo patinado", "D09",
            "Poro Crema", "398", "Poro Blanco", "Nogal Nº3", "Nogal Nº4",
            "190", "506", "L09", "290",
            "D01", "Nogal Nº3", "Nogal Nº2", "H03", "L02",
            "L01", "T02", "301", "Piedra Patinado",
            "Piedra Patinado", "Poro Piedra", "105", "1001", "290",

        ],

    grosores:
        [
            "22 mm", "22 mm", "22 mm", "22 mm", "19 mm", "22 mm",
            "22 mm", "19 mm", "19 mm", "22 mm", "22 mm", "22 mm",
            "22 mm", "19 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "19 mm",
            "19 mm", "19 mm", "19 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", 
            "22 mm", "22 mm", "22 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", 
            "22 mm", "22 mm", "22 mm", "22 mm", "22 mm",
            "22 mm", "22 mm", "22 mm", "22 mm", 
            "22 mm", "22 mm", "22 mm", "22 mm",  "22 mm",
        ],
    
    urls:
        [
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876050/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/01_ALASKA_z6ttsn.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876049/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/02_ASTURIAS_ROBLE_qdnxce.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876049/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/03_BAH%C3%8DA_ROBLE_NUDOS_hvcmpw.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876050/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/04_CALABRIA_10_ALDER_wbury9.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876051/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/05_CALABRIA_10_MARINO_CEREZO_wdiz7f.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876051/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/06_CANTABRIA_8_CASTA%C3%91O_fvxf5e.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876051/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/07_COIMBRA_ROBLE_diodg1.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876051/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/08_ESPINOSA_CEREZO_lxwxsp.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876051/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/09_LEIRIA_14_CEREZO_ghpy1m.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876051/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/10_OVIEDO_CASTA%C3%91O_jcnaze.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876051/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/11_PENAGOS_NOGAL_jtdtbn.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876052/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/12_SEGURA_ALDER_txrhgq.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876052/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/13_PALENCIA_ABETO_x1uaew.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876052/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/14_PALENCIA_ABETO_c28z7b.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876052/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/15_PALENCIA_ABETO_tfoeda.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876052/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/16_PALENCIA_ABETO_yjr7vo.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876052/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/17_MADRID_ABETO_bp7gbx.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876053/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/18_MADRID_ROBLE_ir11gr.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876053/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/19_MADRID_ROBLE_z6krst.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876052/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/20_MADRID_ROBLE_NATURAL_ohucwd.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876053/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/25_VEGA_ROBLE_NUDOS_idzibr.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876053/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/26_VEGA_ROBLE_NUDOS_vttjwx.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876047/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/27_VEGA_ROBLE_NUDOS_g8ws20.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876053/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/21_VEGA_ROBLE_jxcqc2.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876053/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/22_VEGA_ROBLE_ganwhe.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876053/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/23_VEGA_ROBLE_y7vdd6.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876053/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/24_VEGA_ABETO_fzzszw.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876047/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/28_ANDROS_FRESNO_q1bo59.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876047/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/29_CORINTIA_ROBLE_clqppb.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876047/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/30_COPENHAGUE_ROBLE_r9igtr.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876048/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/31_%C3%89GABRO_ROBLE_ssge2a.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876048/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/32_KANSAS_CASTA%C3%91O_kgrosv.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876048/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/33_MILAN_FRESNO_zsio7t.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876048/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/34_NASTUR_ROBLE_z30jcv.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876048/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/35_NIZA_ROBLE_qzetd3.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876048/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/36_NUBE_NOGAL_ona1cv.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876049/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/37_PALMA_NOGAL_r2wxbb.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876050/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/46_AMBERES_ALDER_tjzquy.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876050/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/45_BARI_ROBLE_zn8w1t.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876050/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/44_CIES_ROBLE_ncl84i.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876050/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/43_C%C3%93RCEGA_ROBLE_rmvuhk.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876049/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/38_PARIS_ROBLE_icrmjx.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876049/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/39_PICASSENT_hqekqg.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876049/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/40_SALZBURGO_NOGAL_la6nkn.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876049/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/41_SALZBURGO_ROBLE_ojm845.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876049/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/42_SANTORINI_PINO_oxf8ut.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876047/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/50_%C3%89VORA_ROBLE_c7d4up.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876050/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/49_%C3%8DTACA_CASTA%C3%91O_qe7888.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876050/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/47_LISBOA_ALDER_juuevj.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876050/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/48_OPORTO_ROBLE_bxkpme.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876051/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/55_ROMA_ROBLE_y0caee.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876049/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/54_SEUL_ROBLE_p7o7ws.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876048/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/53_TOLEDO_ROBLE_h8nbli.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876047/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/51_VENECIA_ROBLE_kck8br.png",
            "https://res.cloudinary.com/sl-cocilux/image/upload/f_auto/v1621876047/DISE%C3%91OS%20Y%20MATERIALES/7.%20MADERAS/MATERIALES/52_VERSALLES_ALDER_e3w5eg.png",
            
        ]
    
};

export const EncimerasData=
{
    
    estilos_formica :
        [
            "formica--1907-mate", "formica--279-stucco", "formica--3306-br",
            "formica--3308-br", "formica--3309-br", "formica--3313-br",
            "formica--3584-br", "formica--3585-br", "formica--3586-br",
            "formica--3314-br", "formica--3580-br", "formica--3315-br",
            "formica--6261-stucco", "formica--042-anc" , "formica--acacia-choco-inca",
            "formica--are-261-stone", "formica--arena-blanca", "formica--blanco-soft",
            "formica--anm-047", "formica--ang-044", "formica--ann-048",
            "formica--cai-036-soft", "formica--cal-800-feel", "formica--cat-178-soft",
            "formica--veo-774-wax", "formica--f271-br", "formica--f281-br",
            "formica--cemento", "formica--cemento-gris", "formica--cov-222-tf",
            "formica--f9483-ferro", "formica--glm-246-pietra", "formica--glp-283-pietra",
            "formica--granito-gala", "formica--gris-suave", "formica--gris",
            "formica--ipanema-blue", "formica--iquitos-inca", "formica--kmm-740-wax",
            "formica--lar-770-soft", "formica--lima-inca", "formica--lsb-777-pietra",
            "formica--marmol-zeus", "formica--mta-768-br", "formica--mtc-769-br",
            "formica--luna-nueva", "formica--marmol-blanco", "formica--marmol-ulloa",
            "formica--nazca-inca", "formica--negro-manchego", "formica--negro-trento",
            "formica--rga-9620-br", "formica--rgr-9640-br", "formica--roble-burgos",
            "formica--oas-278-stone", "formica--pino-tampere", "formica--piz-224-soft",
            "formica--roble-hercules", "formica--roble-olbia", "formica--roble-san-simon",
            "formica--pizarra-lousa", "formica--pizarra", "formica--pp-5564-br",
            "formica--roble-teyeu", "formica--roble-winchester", "formica--ron-803",
            "formica--pp-5568-br", "formica--pp-7581-soft", "formica--rg-102-br",
            "formica--terra", "formica--tra-260-stone", "formica--vec-73-wax",
            "formica--ros-804", "formica--rustico", "formica--sln-440-rock",
            "formica--vintage-wood",  "formica--woo-242-soft", "formica--eve-801-feel"
        
        ],
    
    modelos_formica:
        [
                "1907 MATE", "279 STUCCO", "3306 BR",
                "3308 BR", "3309 BR", "3313 BR",
                "3584 BR", "3585 BR", "3586 BR",
                "3314 BR", "3580 BR", "3315 BR",
                "6261 STUCCO", "042 ANC", "ACACIA CHOCO INCA",
                "ARE 261 STONE", "ARENA BLANCA", "BLANCO SOFT",
                "ANM 047", "ANG 044", "ANN 048",
                "CAI 036 SOFT", "CAL 800 FEEL", "CAT 178 SOFT",
                "VEO 774 WAX", " F271 BR",  "F281 BR",
                "CEMENTO", "CEMENTO GRIS", "COV 222 TF",
                "F9483 FERRO", "GLM 246 PIETRA", "GLP 283 PIETRA",
                "GRANITO GALA", "GRIS SUAVE", "GRIS",
                "IPANEMA BLUE", "IQUITOS INCA", "KMM 740 WAX", 
                "LAR 770 SOFT", "LIMA INCA", "LSB 777 PIETRA",
                "MARMOL ZEUS", "MTA 768 BR", "MTC 769 BR",
                "LUNA NUEVA", "MARMOL BLANCO", "MARMOL ULLOA",
                "NAZCA INCA", "NEGRO MANCHEGO", "NEGRO TRENTO",
                "RGA 9620 BR", "RGR 9640 BR", "ROBLE BURGOS",
                "OAS 278 STONE", "PINO TAMPERE", "PIZ 224 SOFT",
                "ROBLE HERCULES", "ROBLE OLBIA", "ROBLE SAN SIMON",
                "PIZARRA LOUSA", "PIZARRA", "PP 5564 BR",
                "ROBLE TEYEU", "ROBLE WINCHESTER", "RON 803",
                "PP 5568 BR", "PP 7581 SOFT", "RG 102 BR",
                "TERRA", "TRA 260 STONE", "VEC 73 WAX",
                "ROS 804", "RUSTICO", "SLN 440 ROCK",
                "VINTAGE WOOD", "WOO 242 SOFT", "EVE 801 FEEL",
        ],
    
    estilos_cuarzo:
        [
            "cuarzo--calacatia",  "cuarzo--acuarela", "cuarzo--amazon",
            "cuarzo--alaska", "cuarzo--añil", "cuarzo--beige-galaxy",
            "cuarzo--blanco-polar", "cuarzo--capuchino", "cuarzo--carrara",
            "cuarzo--blanco-arenal", "cuarzo--blanco-diamante", "cuarzo--blanco-glacial",
            "cuarzo--ceniza-galaxy", "cuarzo--cherry", "cuarzo--cyclon",
            "cuarzo--cielo", "cuarzo--tabla-circon", "cuarzo--colorado",
            "cuarzo--eclipse", "cuarzo--forest", "cuarzo--gris-niebla",
            "cuarzo--marfil-luxe", "cuarzo--marte", "cuarzo--tabla-mercurio",
            "cuarzo--negro-galaxy", "cuarzo--oasis", "cuarzo--oceano-galaxy",
            "cuarzo--tabla-pirineos", "cuarzo--pistacho", "cuarzo--tabla-plata",
            "cuarzo--tabla-platinum", "cuarzo--tabla-plomo", "cuarzo--tabla-rainbow",
            "cuarzo--tabla-tierra", "cuarzo--tabla-triana", "cuarzo--tabla-volcan",
            "cuarzo--tabla-rojo-carmesi", "cuarzo--tabla-sapeli", "cuarzo--tabla-seda",
            "cuarzo--tabla-tropicana", "cuarzo--tabla-turquesa", "cuarzo--tabla-ultramar",
            "cuarzo--tabla-silver", "cuarzo--tabla-sol", "cuarzo--terracota",
        ],
    
    modelos_cuarzo: 
        [
            "CALACATIA", "ACUARELA", "AMAZON",
            "ALASKA", "AÑIL", "BEIGE GALAXY",
            "BLANCO POLAR", "CAPUCHINO", "CARRARA",
            "BLANCO ARENAL", "BLANCO DIAMANTE", "BLANCO GLACIAL",
            "CENIZA GALAXY", "CHERRY", "CYCLON",
            "CIELO", "TABLA CIRCON", "COLORADO",
            "ECLIPSE", "FOREST", "GRIS NIEBLA",
            "MARFIL LUXE", "MARTE", "TABLA MERCURIO",
            "NEGRO GALAXY", "OASIS", "OCEANO GALAXY",
            "TABLA PIRINEOS", "PISTACHO", "TABLA PLATA",
            "TABLA PLATINUM", "TABLA PLOMO", "TABLA RAINBOW",
            "TABLA TIERRA", "TABLA TRIANA", "TABLA VOLCAN",
            "TABLA ROJO CARMESI", "TABLA SAPELI", "TABLA SEDA",
            "TABLA TROPICANA", "TABLA TURQUESA", "TABLA ULTRAMAR",
            "TABLA SILVER", "TABLA SOL", "TERRACOTA",
        ],
    
    types:
        [
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
            "clear","clear","clear",
            "dark","dark","dark",
        ],
};